import React, { useState, useEffect } from "react"
import {
  Container,
  Form,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Table,
  Button,
} from "reactstrap"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import {
  getAttendStudList,
  getCurrentAcadid,
  markAbsentStud,
  getAttendReportReset
} from "store/actions"
import { Tbody, Thead } from "react-super-responsive-table"
import Spinners from "components/Common/Spinner"
import { toast } from "react-toastify"
import DeleteModal from "components/Common/DeleteModal"
import moment from "moment/moment"
const Attendance = () => {
  const dispatch = useDispatch()

  const { studList, AbsentList, Loading, errors, successMsg } = useSelector(
    state => state.AttendanceReducer
  )

  const token = JSON.parse(localStorage.getItem("Token"))
  const [div, setDiv] = useState([])
  const { acadID } = useSelector(state => state.CommonReducer)
  const [showAbsentModal, setShowAbsentModal] = useState(false)
  const [inputData, setInputData] = useState({
    acad_id: "",
    abdate: "",
    sort_order_select: "",
    // sortorderall: "",
    // namesequence: 'ssi.lname ," ",ssi.fname," ", ssi.mname',
  })

  const [checkBox, setCheckBox] = useState({
    webCheck: 1,
    appCheck: 1,
    emailCheck: 1,
    smsCheck: 1,
  })

  const [abStudData, setAbStudData] = useState({
    allStdUserIdsArr: [],
    abStdUserIdsArr: [],
    destination: [],
  })

  const [checkboxesList, setCheckboxesList] = useState({})

  const handleCheckBox = inputCheck => {
    setCheckBox({
      ...checkBox,
      [inputCheck.target.name]: inputCheck.target.checked ? 1 : 0,
    })
  }

  const handleInputChange1 = e => {
    const { classdiv, classid } = JSON.parse(e.target.value)
    // console.log(e.target.value)
    setInputData({
      ...inputData,
      classdiv_id: classdiv,
      class_id: classid,
    })
  }

  const handleInputChange = inputs => {
    setInputData({
      ...inputData,
      [inputs.target.name]: inputs.target.value,
    })
  }
  const handleStudCheck = checkInput => {
    const newCheckboxes = {
      ...checkboxesList,
      [checkInput.target.value]: checkInput.target.checked,
    }
    setCheckboxesList(newCheckboxes)
    if (checkInput.target.checked) {
      setAbStudData(prevState => ({
        ...prevState,
        abStdUserIdsArr: [
          ...prevState.abStdUserIdsArr,
          checkInput.target.value,
        ],
      }))
    } else {
      setAbStudData(prevState => ({
        ...prevState,
        abStdUserIdsArr: prevState.abStdUserIdsArr.filter(
          id => id !== checkInput.target.value
        ),
      }))
    }
  }

  const InsertStudAllToArray = () => {
    let AllStudents = []
    for (let i = 0; i < studList.length; i++) {
      AllStudents.push(studList[i].user_id)
    }
    return AllStudents
  }



  //  SUBMIT ATTENDANCE
  const submitData = () => {
    const data = {
      acad_id:inputData.acad_id,
      class_id:inputData.class_id,
      classdiv_id:inputData.classdiv_id,
      abdate: moment(inputData.abdate).format('DD-MM-YYYY'),
      sort_order_select:parseInt(inputData.sort_order_select),
      student_list:abStudData.abStdUserIdsArr,
      destination:abStudData.destination
    }
    // console.log(data)

    dispatch(markAbsentStud({data}))
   
    setShowAbsentModal(false)
  }

  useEffect(() => {
    if (errors && errors !== null) {
      toast.error(errors)
    }
    if (successMsg && successMsg !== null) {
      dispatch(getAttendReportReset())
      toast.success(successMsg)
      
    }
  }, [errors, successMsg])


  useEffect(() => {
    if (studList && Array.isArray(studList)) {
      const AllStud = InsertStudAllToArray(studList)
      setAbStudData({
        ...abStudData,
        allStdUserIdsArr: AllStud,
      })
    }
  }, [studList])

  useEffect(() => {
    if (acadID.acadid) {
      setInputData({ ...inputData, acad_id: acadID.acadid })
    }
  }, [acadID])

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
  }, [])


  // CURRENT DATE
  useEffect(() => {
    // dispatch(getAttendReportReset())
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`

    setInputData(prev => ({
      ...prev,
      abdate: formattedDate,
    }))
  }, [])


  // GET CLASS DIV
  useEffect(() => {
    ///show-students-attendance
    const getClassDiv = async () => {
      try {
        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/examfillmarks/get-classdiv-list",
          data: {
            acad_id: acadID.acadid,
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setDiv(res.data.data)
        })
      } catch (error) {
        console.log(error)
      }
    }

    if (acadID.acadid) {
      getClassDiv()
    }
  }, [acadID.acadid])

  useEffect(() => {
    const getHolidays = async ({ acad, classdiv, abdate }) => {
      const data = {
        acad_id: acad,
        classdiv_id: classdiv,
        abdate: abdate,
      }
      try {
        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/attendance/check-student-holidays",
          data: data,
          headers: {
            Authorization: token,
          },
        }).then(res => {
          // setDiv(res.data.data);
        })
      } catch (error) {
        console.log(error)
      }
    }

    if (acadID.acadid && inputData.classdiv_id && inputData.abdate) {
      getHolidays({
        acad: acadID.acadid,
        classdiv: inputData.classdiv_id,
        abdate: inputData.abdate,
      })
    }
  }, [acadID.acadid, inputData.classdiv_id, inputData.abdate])

  useEffect(() => {
    const destinationArray = Object.keys(checkBox)
      .filter(key => checkBox[key] === 1)
      .map(key => key.replace("Check", "").toLowerCase())

    setAbStudData(prev => ({
      ...prev,
      destination: destinationArray,
    }))
  }, [checkBox])

  useEffect(() => { 
    if (
      inputData.acad_id !== "" &&
      inputData.classdiv_id !== "" &&
      inputData.abdate !== "" &&
      inputData.sort_order_select !== ""
    ) {
      // getStudentList();
      dispatch(getAttendStudList({
        acad_id: inputData.acad_id,
        classdiv_id: inputData.classdiv_id,
        abdate: moment(inputData.abdate).format("DD-MM-YYYY"),
        sortOrderSelect: parseInt(inputData.sort_order_select),
        frommodule: "attendance",
        userside: "teacherside",
      }))
    }
  }, [inputData]);
  
  useEffect(() => {
    if (studList && AbsentList) {
      const newCheckboxes = {};  
      
      const absentStudentIds = AbsentList.flatMap(absentGroup => 
        absentGroup.map(absent => String(absent.user_id)) 
      );
  
      const updatedAbStdUserIdsArr = studList
        .filter(student => absentStudentIds.includes(String(student.user_id)))
        .map(student => String(student.user_id)); 
  
      studList.forEach(student => {
        const isAbsent = absentStudentIds.includes(String(student.user_id)); 
        newCheckboxes[String(student.user_id)] = isAbsent; 
      });
  
      setCheckboxesList(newCheckboxes);
      setAbStudData(prevState => ({
        ...prevState,
        abStdUserIdsArr: updatedAbStdUserIdsArr,
      }));
    }
  }, [studList, AbsentList]);
  
  
  
  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <Container fluid={true}>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <FormGroup>
                <div className="w-100 d-flex justify-content-around px-0 mx-0">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="webCheck"
                      onClick={e => handleCheckBox(e)}
                      type="checkbox"
                      id="WebSelect"
                      checked={checkBox.webCheck === 1}
                    />
                    <label className="form-check-label" htmlFor="WebSelect">
                      Web
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="appCheck"
                      onClick={e => handleCheckBox(e)}
                      type="checkbox"
                      id="appSelect"
                      checked={checkBox.appCheck === 1}
                    />
                    <label className="form-check-label" htmlFor="appSelect">
                      App
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="emailCheck"
                      onClick={e => handleCheckBox(e)}
                      type="checkbox"
                      id="emailSelect"
                      checked={checkBox.emailCheck === 1}
                    />
                    <label className="form-check-label" htmlFor="emailSelect">
                      Email
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="smsCheck"
                      onClick={e => handleCheckBox(e)}
                      type="checkbox"
                      id="smsSelect"
                      checked={checkBox.smsCheck === 1}
                    />
                    <label className="form-check-label" htmlFor="smsSelect">
                      Sms
                    </label>
                  </div>
                </div>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="Selectteachers" className="form-label">
                  Class Division
                </Label>
                <Input
                  id="Selectteachers"
                  name="classdiv_id"
                  type="select"
                  className="form-control"
                  onChange={handleInputChange1}
                >
                  <option value={""}>Select Divisions</option>
                  {div.length > 0 &&
                    div.map((classDiv, index) => (
                      <option
                        key={classDiv.id}
                        value={JSON.stringify({
                          classdiv: classDiv.id,
                          classid: classDiv.class_id,
                        })}
                      >
                        {classDiv.div_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="selectSort" className="form-label">
                  Sort Order
                </Label>
                <Input
                  id="selectSort"
                  name="sort_order_select"
                  type="select"
                  className="form-control"
                  onChange={handleInputChange}
                >
                  <option value={""}>Sort Order</option>

                  <option value={1001}>Roll No, Gender, Name</option>
                  <option value={1002}>Gender, Last name</option>
                  <option value={1003}>Last Name</option>
                  <option value={1004}>First Name</option>
                  <option value={1005}>Gr.No</option>
                </Input>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="selectdate" className="form-label">
                  Absent Date
                </Label>
                <Input
                  id="selectdate"
                  name="abdate"
                  type="date"
                  value={inputData.abdate}
                  className="form-control"
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Form>
            {Loading ? (
              <div className="">
                <Spinners />
              </div>
            ) : (
              studList &&
              Array.isArray(studList) &&
              studList.length > 0 && (
                <>
                  <Table className="w-100 table-sm">
                    <Thead>
                      <tr>
                        <th>Roll No</th>
                        <th>Name</th>
                        <th className="text-danger text-center">Absent</th>
                      </tr>
                    </Thead>
                    <Tbody>
                      {studList.map((data, index) => (
                        <tr key={index}>
                          <td>{data.roll_no}</td>
                          <td>{data.name}</td>
                          <td className="d-flex justify-content-center align-items-center">
                            <div className="form-check">
                              <input
                                className={`form-check-input ${
                                  checkboxesList[data.user_id]
                                    ? `bg-danger`
                                    : ""
                                }`}
                                type="checkbox"
                                value={data.user_id}
                                checked={checkboxesList[data.user_id] || false}
                                onChange={handleStudCheck}
                                id={`flexCheckChecked${index}`}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </Tbody>
                  </Table>
                  <Button
                    color={"primary"}
                    onClick={() => setShowAbsentModal(true)}
                  >
                    Submit
                  </Button>
                </>
              )
            )}
          </Container>

          <DeleteModal
            show={showAbsentModal}
            onDeleteClick={submitData}
            onCloseClick={() => setShowAbsentModal(false)}
            displayMessage={"Do you want selected student to marked absent?"}
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default Attendance
