import {
  GET_TEST_MARKS_SUCCESS,
  GET_TEST_MARKS,
  GET_TEST_MARKS_RESET,
  GET_TEST_MARKS_FAILED,
  Event_Month_Report_Start,
  Event_Month_Report_FAILED,
  Event_Month_Report_SUCCESS,
  Event_Month_Report_RESET,
} from "./Constant"

export const GetTestStart = payload => ({
  type: GET_TEST_MARKS,
  payload,
})

export const GetTestSuccess = payload => ({
  type: GET_TEST_MARKS_SUCCESS,
  payload,
})

export const GetTestFailure = payload => ({
  type: GET_TEST_MARKS_FAILED,
  payload,
})
export const GetTestReset = payload => ({
  type: GET_TEST_MARKS_RESET,
})

export const GetEventMonthStart = payload => ({
  type: Event_Month_Report_Start,
  payload,
})

export const GetEventMonthSuccess = payload => ({
  type: Event_Month_Report_SUCCESS,
  payload,
})

export const GetEventMonthFailure = payload => ({
  type: Event_Month_Report_FAILED,
  payload,
})

export const GetEventMonthReset = () => ({
  type: Event_Month_Report_RESET,
})
