import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const getTestApi = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.post(
      `${liveApiUrl}/api/classtestfillmarks/get-test-data`,
      payload,
      { headers }
    )
    return response
  } catch (error) {
    console.error("[API] Error Gettting Time Table:", error)
    throw error
  }
}



export const getEventMonthReport = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.post(
      `${liveApiUrl}/api/teacher-anecdotal/get-datewise-fill-records`,
      payload,
      { headers }
    )
    return response
  } catch (error) {
    console.error("[API] Error Gettting Time Table:", error)
    throw error
  }
}
