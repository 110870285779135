import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const getDashIconsAPI = async () => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.get(
      `${liveApiUrl}/api/dashboard/get-dashboard-menu`,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}

export const getBottomNavApi = async () => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.get(
      `${liveApiUrl}/api/dashboard/get-bottom-nav`,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}

export const getFeesUrl = async () => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }
    const response1 = await axios.get(
      `${liveApiUrl}/api/tscommon/getUserAcadID`,
      { headers }
    )
    // console.log(response1.data.acadId.acad_id)
    const acad_id = response1.data.acadId.acad_id
    const response = await axios.post(
      `${liveApiUrl}/api/parent-payfees/getLink`,
      { acad_id },
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}
