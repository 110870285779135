import React, { useEffect, useState, useMemo } from "react"
import { Card, CardBody, Col, Container, Row, Table, CardImg } from "reactstrap"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentAcadid, getDivisionStart } from "store/Common/Actions"

const CardD = ({ title, value }) => {
  return (
    <Col xs={12} md={6}>
      <div className="d-flex justify-content-between">
        <p className="fw-bold text-dark fs-6 mb-1">{title}:</p>
        <p className="mb-1 fs-6 text-dark">{value}</p>
      </div>
    </Col>
  )
}
const Home = () => {
  const dispatch = useDispatch()
  const { acadID, division } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))
  let baseUrl = "https://marcopolo.prisms.in/api/examfillmarks"

  const [loading, setLoading] = useState(false)
  const [div_id, setDiv_id] = useState("")
  const [divName, setDivName] = useState()
  const [studInfo, setStudInfo] = useState()
  const [studentList, setStudentList] = useState([])
  const [EventReport, setEventReport] = useState()
  const [stud, setStud] = useState()
  const [academic, setAcademic] = useState([])
  const [acad_id, setAcad_id] = useState("")

  const getAcademicYear = async () => {
    try {
      await axios({
        method: "GET",
        url: `${baseUrl}/get-Academic-year`,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setAcademic(res.data.data)
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }

    getAcademicYear()
  }, [])

  useEffect(() => {
    if (acad_id || acadID.acadid) {
      dispatch(
        getDivisionStart({
          acad_id: acad_id || acadID.acadid,
        })
      )
    }
  }, [acadID.acadid, acad_id])

  useEffect(() => {
    const getStudentList = async () => {
      setLoading(true)
      try {
        const response = await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/notification/get-student_list",
          data: {
            acad_id: acad_id || acadID.acadid,
            classdiv_id: div_id,
          },
          headers: {
            Authorization: token,
          },
        })
        setStudentList(response.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    if ((acad_id || acadID.acadid) && div_id) {
      getStudentList()
    }
  }, [acadID.acadid, div_id, acad_id])

  const getEventWiseReport = async () => {
    try {
      const data = {
        acad_id: acad_id || acadID.acadid,
        // acad_id: -1,
        stud_id: parseInt(stud),
        division_id: div_id,
      }
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/teacher-anecdotal/eventwise-report",
        data: data,
        headers: {
          Authorization: token,
        },
      })
        .then(res => {
          console.log(res.data.data[4])
          setStudInfo(res.data.data[4])
          setEventReport(res.data.data[2])
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {}
  }

  useEffect(() => {
    if (stud) {
      getEventWiseReport()
    }
  }, [stud])
  return (
    <div className="">
      <Container>
        <Card className="shadow-lg rounded-lg p-0">
          <CardBody className="p-2">
            <Row>
              <Col xl="12" md="12" sm="12">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={e => setAcad_id(e.target.value)}
                >
                  <option value={acadID.acadid}>{acadID.academics}</option>
                  {academic &&
                    academic
                      .filter(year => year.acadid !== acadID.acadid)
                      .map(year => (
                        <option key={year.acadid} value={year.acadid}>
                          {year.academics}
                        </option>
                      ))}
                </select>
              </Col>
              <Col xl="4" className="mt-3">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={e => {
                    const selectedItem = JSON.parse(e.target.value)
                    setDiv_id(selectedItem.id)
                    setDivName(selectedItem.div_name)
                  }}
                >
                  <option>Select Division</option>
                  {division &&
                    division.length > 0 &&
                    division.map((item, index) => (
                      <option
                        key={item.id}
                        value={JSON.stringify({
                          id: item.id,
                          div_name: item.div_name,
                        })}
                      >
                        {item.div_name}
                      </option>
                    ))}
                </select>
              </Col>

              <Col xl="4" className="mt-3">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={e => {
                    setStud(e.target.value)
                  }}
                >
                  <option>Select Student</option>
                  {studentList &&
                    studentList.length > 0 &&
                    studentList.map((item, index) => (
                      <option key={item.id} value={item.user_id}>
                        {item.student_name}
                      </option>
                    ))}
                </select>
              </Col>
            </Row>
            <Row>
              <Col xl="12" md="12" sm="12" className="mt-0">
                {studInfo?.map((item, index) => (
                  <Card key={index}>
                    <CardBody>
                    <div className="text-center mb-3 mt-1">
                    <CardImg
                      alt="Profile"
                      className="rounded-circle"
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                      }}
                      src={
                        item.avatar
                          ? item.avatar
                          : "https://ocisa.prisms.in/images/dashboard/student.png"
                      }
                    />
                  </div>
                      <CardD title={"Student"} value={item.name} />
                      <CardD title={"Division"} value={item.div_name} />
                      <CardD title={"Gr. No"} value={item.genregid} />
                      <CardD title={"Father's Name"} value={item.father_name} />
                      <CardD title={"Mother's Name"} value={item.mothername} />
                      <CardD title={"Mobile No."} value={item.mobile1} />
                      <CardD title={"Mobile No."} value={item.mobile2} />
                    </CardBody>
                  </Card>
                ))}
              </Col>
            </Row>

            {loading ? (
              <Spinners />
            ) : (
              <Table className="mt-3" bordered striped responsive>
                <thead className="mt-3 table-info">
                  <tr>
                    <th>Academic Year/ Remark Date</th>
                    <th>Remark</th>
                    <th>Teacher's Sign</th>
                  </tr>
                </thead>
                <tbody>
                  {EventReport &&
                    EventReport.map((item, idx) => (
                      <tr key={idx}>
                        <td style={{ whiteSpace: "nowrap" }}>
                          ({item.academics}) <br />
                          {item.created_datetime}
                        </td>

                        <td>{item.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Container>
      <ToastContainer />
    </div>
  )
}

export default Home
