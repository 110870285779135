import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import {
  GET_CURRENT_ACADID,
  GET_STUDENT_LIST_START,
  GET_SUBJECT_START_COMMON,
  GET_DIVISION_START,
} from "./Constants"
import {
  getCurrentAcadIdAPI,
  divisionAPI,
  subjectAPI,
  StudentListApi,
} from "./Api"
import {
  getCurrentAcadidFailed,
  getCurrentAcadidSuccess,
  getDivisionSuccess,
  getDivisionFail,
  getSubjectSuccessCommon,
  getSubjectFailCommon,
  getStudentSuccess,
  getStudentFail,
} from "./Actions"

function* getCurrentAcadId(action) {
  try {
    const response = yield call(getCurrentAcadIdAPI, action.payload)
    if (response) {
      yield put(
        getCurrentAcadidSuccess(
          Array.isArray(response.data) ? response.data[0] : []
        )
      )
    } else {
      throw response
    }
  } catch (error) {
    console.log("error", error)
    yield put(
      getCurrentAcadidFailed(
        error.response.data || "Unable to get Bottom Nav Icons Icons"
      )
    )
  }
}

function* getDivision(action) {
  try {
    const response = yield call(divisionAPI, action.payload)
    if (response) {
      yield put(getDivisionSuccess(response.data.data))
    } else {
      throw response
    }
  } catch (error) {
    console.log("error", error)
    yield put(getDivisionFail(error.response.data || "something went wrong"))
  }
}

function* getSubjectApiAsync(action) {
  try {
    const response = yield call(subjectAPI, action.payload)
    if (response) {
        console.log(response.data.data)
      yield put(getSubjectSuccessCommon(response.data.data))
    } else {
      throw response
    }
  } catch (error) {
    console.log("error", error)
    yield put(getSubjectFailCommon(error.response.data || "something went wrong"))
  }
}

function* getStudentList(action) {
  try {
    const response = yield call(StudentListApi, action.payload)
    if (response) {
      yield put(getStudentSuccess(response.data.data))
    } else {
      throw response
    }
  } catch (error) {
    console.log("error", error)
    yield put(getStudentFail(error.response.data || "something went wrong"))
  }
}
function* CommonSaga() {
  yield takeEvery(GET_CURRENT_ACADID, getCurrentAcadId)
  yield takeLatest(GET_DIVISION_START, getDivision)
  yield takeLatest(GET_SUBJECT_START_COMMON, getSubjectApiAsync)
  yield takeLatest(GET_STUDENT_LIST_START, getStudentList)
  //yield takeLatest(LOGOUT_SU_USER, logoutUser)
}

export default CommonSaga
