import React, { useState } from "react"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import EventWise from "./EventWiseReport"
import SubjectWise from "./SubjectMonthReport"
const Home = () => {
  const [activeTab, setActiveTab] = useState("1")

  return (
    <div className="page-content">
      <Container>
        <Card className="p-0">
          <CardBody className="text-center p-1">
            <h1 className="mb-4 mt-3 fs-3" style={{ color: "#3c3c3c" }}>
             Anecdotal Report
            </h1>

            <Nav tabs className="justify-content-center mb-3">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => setActiveTab("1")}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      activeTab === "1" ? "#007bff" : "transparent",
                    color: activeTab === "1" ? "white" : "black",
                    borderRadius: "8px",
                    padding: "10px 15px",
                  }}
                >
                  Event Wise
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => setActiveTab("2")}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      activeTab === "2" ? "#007bff" : "transparent",
                    color: activeTab === "2" ? "white" : "black",
                    borderRadius: "8px",
                    padding: "10px 15px",
                  }}
                >
                  Monthly/Subject Wise
                </NavLink>
              </NavItem>
            </Nav>

             <div className="mt-3">
              {activeTab === "1" ? <EventWise /> : <SubjectWise />}
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Home
