import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import {
  getBottomNav,
  getDashIcons,
  getSchoolInfo,
  getUrlStart,
} from "store/actions"
import { toast } from "react-toastify"
const SidebarContent = props => {
  const ref = useRef()

  const [sections, setSections] = useState([])

  const { dashIcons, Loading } = useSelector(state => state.DashReducer)

  const dispatch = useDispatch()

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [sections])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const transformData = data => {
    const transformedData = {}

    data.forEach(item => {
      const { section_name, module_name, url, icon } = item
      if (!transformedData[section_name]) {
        transformedData[section_name] = {
          SectionName: section_name,
          Modules: [],
        }
      }
      transformedData[section_name].Modules.push({ module_name, url, icon })
    })

    return Object.values(transformedData)
  }

  useEffect(() => {
    if (Array.isArray(dashIcons) && dashIcons.length > 0) {
      let data = transformData(dashIcons)
      setSections(data)
    }
  }, [dashIcons])

  const tToggle = () => {
    //To close and open sidebar
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } //else {
    //   body.classList.toggle("vertical-collpsed")
    //   body.classList.toggle("sidebar-enable")
    // }
  }

  const group_id = localStorage.getItem("group_id")
  useEffect(() => {
    dispatch(getDashIcons())
    dispatch(getBottomNav())
    if (group_id == 2) {
      dispatch(getUrlStart())
    }
  }, [])

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
            </li>

            {!Loading &&
              sections &&
              sections.length > 0 &&
              sections.map((section, index) => (
                <li key={index}>
                  <Link className="has-arrow">
                    <i className="bx bx-receipt"></i>
                    <span>{props.t(section.SectionName)}</span>
                  </Link>
                  <ul className="sub-menu">
                    {section.Modules.map((module, index2) => {
                      const moduleRegex = new RegExp(/https|http|.in|.com/g)
                      const isExternalLink = moduleRegex.test(module.url)
                      return (
                        <li key={index2}>
                          {
                            <Link
                              to={
                                isExternalLink ? "/external-link" : module.url
                              }
                              state={{ webLink: module.url }}
                              onClick={tToggle}
                            >
                              <i className={module.icon}></i>
                              <span style={{ whiteSpace: "pre-line" }}>
                                {props.t(module.module_name)}
                              </span>
                            </Link>
                          }
                        </li>
                      )
                    })}
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
