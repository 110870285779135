import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import { Link } from "react-router-dom"
import moment from "moment/moment"

const DateCard = ({
  id,
  description,
  name,
  file_location,
  staff_name,
  out_of_marks,
  file_name,
  date,
  subject,
  remarks,
}) => {
  return (
    <Link
      to={`/submit-assignment/${id}`}
      state={{
        id: id,
        description: description,
        date: date,
        name: name,
        file_location: file_location,
        staff_name: staff_name,
        out_of_marks: out_of_marks,
        file_name: file_name,
        subject: subject,
        remarks: remarks,
      }}
    >
      <Card className="mb-3">
        <CardBody>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <span className="mt-3 fs-5 text-start">Subject:</span>
            <span className="mt-1 fs-5 text-dark fw-medium">{subject}</span>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <span className="mt-3 fs-5 text-start">Date:</span>
            <span className="mt-1 fs-5 text-dark fw-medium">
              {moment(date).format("DD-MM-YYYY")}
            </span>
          </div>
        </CardBody>
      </Card>
    </Link>
  )
}

const AssignmentDetails = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const getStudentAssignment = async () => {
    try {
      setLoading(true)
      const resp = await axios.post(
        "https://topschool.prisms.in/rest/index.php/staff_list.json",
        {
          fun_name: "getStudentAssignment",
          sid: localStorage.getItem("sid").replace(/"/g, ""),
          user_id: localStorage.getItem("user_id"),
        }
      )
      setData(resp.data.records || [])
    } catch (error) {
      console.error("Error fetching assignments:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getStudentAssignment()
  }, [])

  console.log(data)
  return (
    <div className="page-content">
      <Container>
        <Card>
          <CardBody>
            <h1 className="text-center mb-4 fs-3" style={{ color: "#3c3c3c" }}>
              Assignment
            </h1>
            {loading ? (
              <Spinners />
            ) : data && data.length > 0 ? (
              data.map((item, index) => (
                <React.Fragment key={item.id}>
                  {item.status_is_publish === "1" && (
                    <DateCard
                      id={item.id}
                      description={item.description}
                      name={item.name}
                      file_location={item.file_location}
                      date={item.date}
                      staff_name={item.staff_name}
                      out_of_marks={item.out_of_marks}
                      remarks={item.remarks}
                      subject={item.subject}
                      file_name={item.file_name}
                    />
                  )}
                </React.Fragment>
              ))
            ) : (
              <Card>
                <CardBody>
                  <h5 className="text-center text-muted">
                    No assignments available.
                  </h5>
                </CardBody>
              </Card>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AssignmentDetails
