import React, { useState } from "react"
import {
  Container,
  Card,
  CardBody,
  Input,
  Button,
  Badge,
  Alert,
} from "reactstrap"
import { useLocation } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import axios from "axios"
import moment from "moment/moment"
import { replaceS3Url } from "components/Common/ReplaceS3Url"

const Details1 = ({ title, value }) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center mb-3">
      <span className="text-dark fw-bold w-50">{title}:</span>
      {/* <span className="text-dark w-50 text-break">{value}</span> */}
      <a
        href={value}
        target="_blank"
        rel="noopener noreferrer"
        className="text-decoration-none text-dark w-50 text-break"
      >
        <Badge
          color="success"
          className="px-3 py-2 text-white"
          style={{
            borderRadius: "12px",
            fontWeight: "500",
          }}
        >
          Download
        </Badge>
      </a>
    </div>
  )
}

const Details = ({ title, value }) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center mb-3">
      <span className="text-dark fw-bold w-50">{title}:</span>
      <span className="text-dark w-50 text-break">{value}</span>
    </div>
  )
}

const Submit = () => {
  const token = JSON.parse(localStorage.getItem("Token"))

  const live_url = ""
  const location = useLocation()
  const [error, setError] = useState(false)
  const [file, setFile] = useState()
  const [Loading, setLoading] = useState(false)

  const handleSubmitAssignment = async e => {
    e.preventDefault()

    try {
      if (!file) {
        setError(true)
        return
      }

      setError(false)
      setLoading(true)
      const fm = new FormData()
      fm.append("assignmentId", location.state.id)
      fm.append("files", file)
      // url: "https://marcopolo.prisms.in/api/assignment/submit-assignment",
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/assignment/submit-assignment",
        data: fm,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }).then(res => {
        // toast.success("Your assignment has been uploaded successfully")

        toast.success(res.data.message)
        setLoading(false)
      })
    } catch (error) {
      console.error("Error submitting assignment:", error)
      toast.error("Something went wrong. Please try again.")
    }
  }

  return (
    <div className="page-content">
      <Container>
        <Card className="shadow-lg border-0 rounded-lg">
          <CardBody className="p-3">
            {/* <h4 className="mb-4 text-center text-dark">Submit Assignment</h4> */}
            <Details title="Subject" value={location.state.subject} />
            <Details
              title="Date"
              value={moment(location.state.date).format("DD-MM-YYYY")}
            />
            <Details title="description" value={location.state.description} />
            <Details title="Teacher Name" value={location.state.staff_name} />
            <Details title="Teacher Remarks" value={location.state.remarks} />

            <Details1
              title="Assignment"
              value={replaceS3Url(location.state.file_location)}
            />
            {location.state.remarks == null || location.state.remarks == "" ? (
              <div className="mt-4">
                <Input
                  id="fileUpload"
                  type="file"
                  className="form-control mb-4"
                  onChange={e => {
                    setError()
                    setFile(e.target.files[0])
                  }}
                />
              </div>
            ) : null}
            {error ? (
              <Alert color="danger">Attachments are required</Alert>
            ) : null}
            {location.state.remarks == null || location.state.remarks == "" ? (
              <div className="text-center mt-1">
                <Button
                  color="primary"
                  className="px-4 py-2"
                  onClick={handleSubmitAssignment}
                >
                  Submit
                </Button>
              </div>
            ): null}
          </CardBody>
        </Card>
        <ToastContainer />
      </Container>
    </div>
  )
}

export default Submit
