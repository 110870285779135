import React, { useEffect, useState, useMemo } from "react"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentAcadid } from "store/Common/Actions"
import moment from "moment"

const Home = () => {
  const dispatch = useDispatch()
  const { acadID } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))
  let baseUrl = "https://marcopolo.prisms.in/api/examfillmarks"
  // let baseUrl = "http://localhost:8080/api/examfillmarks"

  const [loading, setLoading] = useState(false)
  const [div, setDiv] = useState([])
  const [div_id, setDiv_id] = useState("")
  const [divName, setDivName] = useState()
  const [subList, setSubList] = useState([])
  const [subName, setSubName] = useState()
  const [subject_id, setSubject_id] = useState("")
  const [outOfMarks, setOutOfMark] = useState()
  const [sortOrderBY, setSortOrderBy] = useState("roll_no")
  const [studentList, setStudentList] = useState([])
  const [date, setDate] = useState()
  const [testName, setTest] = useState()

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
  }, [])

  useEffect(() => {
    const getClassDiv = async () => {
      try {
        const response = await axios({
          method: "POST",
          url: `${baseUrl}/get-classdiv-list`,
          data: {
            acad_id: acadID.acadid,
          },
          headers: {
            Authorization: token,
          },
        })
        setDiv(response.data.data)
      } catch (error) {
        console.log(error)
      }
    }

    if (acadID.acadid) {
      getClassDiv()
    }
  }, [acadID.acadid])
  useEffect(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    setDate(formattedDate)
  }, [])
  useEffect(() => {
    const getSubjectList = async () => {
      try {
        const response = await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/classtestfillmarks/get-classtest-subjects",
          data: {
            acad_id: acadID.acadid,
            classdiv_id: div_id,
          },
          headers: {
            Authorization: token,
          },
        })
        setSubList(response.data.data)
      } catch (error) {
        console.log(error)
      }
    }

    if (div_id) {
      getSubjectList()
    }
  }, [div_id])

  useEffect(() => {
    const getStudentList = async () => {
      setLoading(true)
      try {
        const response = await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/classtestfillmarks/get-student-details",
          data: {
            acad_id: parseInt(acadID.acadid),
            classdiv_id: parseInt(div_id),
            subject_id: parseInt(subject_id),
          },
          headers: {
            Authorization: token,
          },
        })
        setStudentList(response.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    if (subject_id && div_id) {
      getStudentList()
    }
  }, [subject_id, sortOrderBY])
  const [marks, setMarks] = useState(Array(studentList.length).fill(""))
  const [status, setStatus] = useState(Array(studentList.length).fill(""))

  const handleChangeMarks = (e, index) => {
    let value = e.target.value.trim() // Remove extra spaces

    // Allow only numeric input (including empty string for deletions)
    if (!/^\d*$/.test(value)) return

    let numericValue = Number(value) // Convert to number

    // Check if the number exceeds outOfMarks
    if (numericValue > outOfMarks) {
      return toast.error(`Marks should be less than or equal to ${outOfMarks}`)
    }

    // Update marks
    setMarks(prevMarks => {
      const updatedMarks = [...prevMarks]
      updatedMarks[index] = value // Keep as a string to handle empty input
      return updatedMarks
    })

    // Reset status
    setStatus(prevStatus => {
      const updatedStatus = [...prevStatus]
      updatedStatus[index] = ""
      return updatedStatus
    })
  }

  const handleStatusChange = (index, newStatus) => {
    setStatus(prevStatus => {
      const updatedStatus = [...prevStatus]
      updatedStatus[index] = newStatus
      return updatedStatus
    })

    setMarks(prevMarks => {
      const updatedMarks = [...prevMarks]

      updatedMarks[index] = newStatus === "Absent" ? "AA" : "NA"
      return updatedMarks
    })
  }
  const formattedMarks = marks.map(mark =>
    /^\d+$/.test(mark) ? Number(mark) : mark
  )
  const handleSubmitTest = async () => {
    try {
      if (!date) return toast.error("Select Date")
      if (!testName) return toast.error("Enter test name")
      if (!outOfMarks) return toast.error("Out of Marks required")

      const data = {
        acad_id: acadID.acadid,
        subject_id: [String(subject_id), String(subName)],
        classdiv_id: [String(div_id), String(divName)],
        test_prefix: testName,
        test_date: moment(date).format("DD-MM-YYYY"),
        out_of_marks: outOfMarks,
        user_ids: studentList?.map(item => item.user_id) || [],
        marks: formattedMarks,
      }

      setLoading(true)
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/classtestfillmarks/create-class-test",
        data: data,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setLoading(false)
        setStudentList([])
        setOutOfMark("")
        setDate("")
        setDiv([])
        setSubList([])
        setTest("")
        toast.success(res.data.data.message)
      })
    } catch (error) {
      setLoading(false)

      toast.error("An error occurred while submitting the test")
    }
  }

  return (
    <div className="page-content">
      <div className="">
        <Container>
          <Card className="shadow-lg rounded-lg p-0">
            <CardBody className="p-2">
              <h1
                className="text-center mb-4 fs-3"
                style={{ color: "#3c3c3c" }}
              >
                Create Class Test
              </h1>
              <Row>
                <Col xl="12" md="12" sm="12">
                  <Input
                    type="date"
                    onChange={e => setDate(e.target.value)}
                    value={date}
                  />
                </Col>

                <Col xl="4" className="mt-2">
                  <Input
                    type="text"
                    placeholder="Test name"
                    value={testName}
                    onChange={e => setTest(e.target.value)}
                  />
                </Col>

                <Col xl="4" className="mt-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => {
                      const selectedItem = JSON.parse(e.target.value)
                      setDiv_id(selectedItem.id)
                      setDivName(selectedItem.div_name)
                    }}
                  >
                    <option>Select Division</option>
                    {div.length > 0 &&
                      div.map((item, index) => (
                        <option
                          key={item.id}
                          value={JSON.stringify({
                            id: item.id,
                            div_name: item.div_name,
                          })}
                        >
                          {item.div_name}
                        </option>
                      ))}
                  </select>
                </Col>

                <Col xl="4" className="mt-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => {
                      const selectedItem = JSON.parse(e.target.value)
                      console.log(selectedItem)
                      setSubject_id(selectedItem.id)
                      setSubName(selectedItem.subject)
                    }}
                  >
                    <option value="">Select Subject</option>
                    {subList &&
                      subList.length > 0 &&
                      subList.map((item, index) => (
                        <option
                          key={item.id}
                          value={JSON.stringify({
                            id: item.id,
                            subject: item.subject,
                          })}
                        >
                          {item.subject}
                        </option>
                      ))}
                  </select>
                </Col>
                <Col xl="12" md="12" sm="12" className="mt-3">
                  <Input
                    type="number"
                    placeholder="Out of marks"
                    value={outOfMarks}
                    onChange={e => setOutOfMark(e.target.value)}
                  />
                </Col>

                <Col xl="12" md="12" sm="12" className="mt-3">
                  <span>Note:</span>
                  <br />
                  <span>1. AA for Absent Student</span>
                  <br />
                  <span>2. NA for Not Applicable Student</span>
                </Col>

                {/* <Col xl="4" className="mt-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => setSortOrderBy(e.target.value)}
                  >
                    <option>Sort By</option>
                    <option value={"roll_no"}>Roll No</option>
                    <option value={"name"}>First Name</option>
                    <option value={"lastname"}>Last Name</option>
                    <option value={"glastname"}>Middle Name</option>
                  </select>
                </Col> */}
              </Row>

              {loading ? (
                <Spinners />
              ) : (
                <Table className="mt-3" bordered striped responsive>
                  <thead className="mt-3 table-info">
                    <tr>
                      <th>Roll No</th>
                      <th>Student Name</th>
                      <th>Marks</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentList &&
                      studentList.map((item, idx) => (
                        <tr key={idx}>
                          <td>{item.roll_no}</td>
                          <td>{item.student_name}</td>
                          <td>
                            <Input
                              type="text" // Allow both numbers and text
                              className="form-control"
                              style={{ width: "90px" }}
                              value={marks[idx] || ""}
                              onChange={e => handleChangeMarks(e, idx)}
                            />
                          </td>
                          <td>
                            <Badge
                              color={
                                status[idx] === "Absent"
                                  ? "danger"
                                  : "secondary"
                              }
                              style={{ cursor: "pointer", marginRight: "5px" }}
                              onClick={() => handleStatusChange(idx, "Absent")}
                            >
                              Absent
                            </Badge>
                            <Badge
                              color={
                                status[idx] === "NA" ? "warning" : "secondary"
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => handleStatusChange(idx, "NA")}
                            >
                              NA
                            </Badge>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
              {studentList.length > 0 && (
                <div
                  className="d-flex justify-content-center"
                >
                  <button
                    className="btn btn-primary mb-5 btn-lg"
                    onClick={handleSubmitTest}
                  >
                    Save Marks
                  </button>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Home
