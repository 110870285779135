import React, { useEffect, useState } from "react"
import {
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap"

import "./NotificationStyle.css"
import { useDispatch, useSelector } from "react-redux"
import { getNotification, getTeacherNotification } from "store/actions"
import moment from "moment"
import Message from "./Message"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Spinners from "components/Common/Spinner"

const Notifications = () => {
  const dispatch = useDispatch()
  const { NotificationList, Loading } = useSelector(
    state => state.NotificationReducer
  )



  const [teacherNotiType, setTeacherNotiType] = useState(1)

  const dates = new Set()

  const renderDate = dateNum => {
    dates.add(dateNum)

    return (
      <div className="my-2 text-center">
        {/* <Button className='btn-sm disabled' color='white'><b className='text-dark'></b></Button> */}
        
        <div className="p-2 mb-1 bg-white d-inline-block border rounded-4 shadow ">
          {dateNum}
        </div>
      </div>
    )
  }

  const ExpandableText = ({ text, descriptionLength, NotificationType }) => {
    const fullText = text.anntext
    const [isExpanded, setIsExpanded] = useState(false)
    const toggleText = () => setIsExpanded(!isExpanded)

    const shouldShowReadMore = fullText.length > descriptionLength

    const notificationTypes = {
      1: "Homework",
      2: "Announcement",
      3: "Student Specific",
    }

    function updateTime(inputDate) {
      const date = new Date(inputDate)

      let hours = date.getUTCHours()
      let minutes = date.getUTCMinutes()

      const ampm = hours >= 12 ? "pm" : "am"

      hours = hours % 12
      hours = hours ? hours : 12
      minutes = minutes + 1

      //const dateString = inputDate;

      //  console.log(`Hours: ${hours}, Minutes: ${minutes}, String ${inputDate}`);

      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")} ${ampm}`
    }

    return (
      <>
        <div style={{ fontSize: "15px" }} className="text-dark">
          <Message
            text={isExpanded ? fullText : fullText.slice(0, descriptionLength)}
          />
          {!isExpanded && shouldShowReadMore && (
            <span
              className="text-primary d-block mb-0 pb-0 fw-semibold"
              onClick={toggleText}
            >
              <b>... Read more</b>
            </span>
          )}
        </div>
        <div className="w-100 p-0 m-0">
          <span className="metadata">
            <span className="time fw-medium text-dark">
              {notificationTypes[NotificationType] || ""}
            </span>
            <span className="time"> {updateTime(text.created)}</span>
          </span>
        </div>
      </>
    )
  }

  const ShowThumbnail = ({ Notification }) => {
    const getFileExtension = url => {
      if (url && url !== "null" && url !== " ") {
        const ext = url.split(".").pop()
        let murl = ""

        if (url.includes("https://s3.amazonaws.com/topschool-attachments")) {
          murl = url.replace(
            "https://s3.amazonaws.com/topschool-attachments",
            "https://files.prisms.in"
          )
        } else {
          murl = url
        }

        if (ext.toLowerCase() === "pdf") {
          return (
            <div className="text-center">
              <i
                className="mdi mdi-file-pdf text-danger"
                style={{ fontSize: "40px" }}
              ></i>
            </div>
          )
        } else if (ext.toLowerCase() === "docx") {
          return (
            <div className="text-center">
              <i
                className="mdi mdi-file-document fs-1 text-primary"
                style={{ fontSize: "40px" }}
              ></i>
            </div>
          )
        } else if (
          ext.toLowerCase() === "jpeg" ||
          ext.toLowerCase() === "png" ||
          ext.toLowerCase() === "jpg"
        ) {
          return (
            <div className="">
              {url ? (
                <img
                  src={murl}
                  width={"100%"}
                  alt="thumbnail"
                  className="rounded-3"
                />
              ) : (
                <img src="" alt="" style={{ display: "none" }} />
              )}
            </div>
          )
        } else if (
          ext.toLowerCase() === "csv" ||
          ext.toLowerCase() === "xlsx"
        ) {
          return (
            <div className="text-center">
              <i
                className="mdi mdi-file-excel text-primary"
                style={{ fontSize: "40px" }}
              ></i>
            </div>
          )
        } else {
          return (
            <div className="text-center">
              <i className="mdi mdi-file" style={{ fontSize: "40px" }}></i>
            </div>
          )
        }
      }
    }

    return (
      <div className="d-flex justify-content p-0">
        {Notification.file_attach ? (
          <div className="p-1 w-100">
            <Link
              to={"/media-preview"}
              state={{
                fileurl: (Notification.file_attach),
              }}
            >
              {getFileExtension(
                Notification.file_attach || Notification.file_attach
              )}
            </Link>
          </div>
        ) : null}

        {Notification.file_attach2 ? (
          <div className="p-1 w-100">
            <Link
              to={"/media-preview"}
              state={{
                fileurl: Notification.file_attach2,
              }}
            >
              {getFileExtension(
                Notification.thumbnail2 || Notification.file_attach2
              )}
            </Link>
          </div>
        ) : null}

        {Notification.file_attach3 ? (
          <div className="p-1 w-100">
            <Link
              to={"/media-preview"}
              state={{
                fileurl: Notification.file_attach3,
              }}
            >
              {getFileExtension(
                Notification.thumbnail3 || Notification.file_attach3
              )}
            </Link>
          </div>
        ) : null}
      </div>
    )
  }

  useEffect(() => {
    const groupID = localStorage.getItem("group_id")
    if (groupID) {
      if (groupID == 2) {
        dispatch(getNotification())
      } else {
        dispatch(getTeacherNotification(teacherNotiType))
      }
    } else {
      toast.error("Group id not found")
    }
  }, [dispatch, teacherNotiType])

  return (
    <div className="page-content">
      {localStorage.getItem("group_id") &&
        localStorage.getItem("group_id") != 2 && (
          <div className="fixed-top" style={{ zIndex: 1, marginTop: "70px" }}>
            <div className="row text-center px-3">
              <div
                className={`col-6 btn ${
                  teacherNotiType == 1 ? " bg-primary text-white" : "bg-white"
                } `}
                onClick={() => setTeacherNotiType(1)}
              >
                Default
              </div>
              <div
                className={`col-6 btn ${
                  teacherNotiType == 2 ? " bg-primary text-white" : "bg-white"
                }`}
                onClick={() => setTeacherNotiType(2)}
              >
                Teachers
              </div>
            </div>
          </div>
        )}
       {/* <div className="notification_search">
       <Input type="text" placeholder="search"/>
       </div> */}
      {Loading ? (
        <Spinners />
      ) : (
        NotificationList &&
        Array.isArray(NotificationList) &&
        NotificationList.map((Notification, index) => {
          const dateNum = moment(Notification.created).format("DD/MM/YYYY")
          const NotificationType =
            Notification.an_type === 1 || Notification.an_type === 6
              ? 1
              : Notification.an_type === 2
              ? 2
              : Notification.an_type === 3 ||
                Notification.an_type === 7 ||
                Notification.an_type === 8 ||
                Notification.an_type === 11 ||
                Notification.an_type === 21 ||
                Notification.an_type === 100 ||
                Notification.an_type === 101
              ? 3
              : ""
          return (
            <>
             <div className="mt-8">
              {dates.has(dateNum) ? null : renderDate(dateNum)}
              <Card
                className={`p-2 mx-auto border ${
                  NotificationType === 1
                    ? "Homework-card"
                    : NotificationType === 2
                    ? "announcement-card"
                    : NotificationType === 3
                    ? "studentSpecific-card"
                    : "bg-light"
                } rounded-3`}
                key={index}
              >
                <ShowThumbnail Notification={Notification} />

                <ExpandableText
                  descriptionLength={370}
                  text={Notification}
                  NotificationType={NotificationType}
                />
              </Card>
            </div>
            </>
           
          )
        })
      )}

      {localStorage.getItem("group_id") &&
      localStorage.getItem("group_id") != 2 ? (
        <div className="fixed-bottom" style={{ zIndex: 0, marginBottom: "6%" }}>
          <Link
            to={"/create-notifications"}
            className="btn btn-primary btn-rounded mb-5 me-3 btn-lg float-end"
            color="primary"
          >
            New Notification{" "}
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default Notifications
