// import React from "react"
// import ResuableComponent from "../../../common/ResuableHome"
// const Home = () => {
//   return (
//     <div>
//       <ResuableComponent
//         title={"Class Test"}
//         r1={"/create-class-test"}
//         r2={"/class-test"}
//         // r3={"/students-assignment"}
//         t1={"Create Test"}
//         t2={"Class Test Marks"}
//         // t3={"Submitted Assignment"}
//       />
//     </div>
//   )
// }

// export default Home
import React from "react"
import { Card, Button, Container, Row, Col } from "reactstrap" // Import Button from reactstrap
import { Link } from "react-router-dom"

const SubjectHome = () => {
  return (
    <div className="page-content">
      <Container className="">
        <Card
          className="shadow-sm p-4"
          style={{
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            maxWidth: "100%",
            margin: "auto",
          }}
        >
          <h1
            className="text-center mt-2 mb-4 fs-2"
            style={{ color: "#3c3c3c", fontWeight: "600" }}
          >
            Class Test{" "}
          </h1>

          <Row className="d-flex justify-content-center flex-column align-items-center justify-content-center">
            <Col xs="auto" className="mt-4 mb-3">
              <Link to={"/create-class-test"}>
                <Button
                  color="primary"
                  className="px-4 py-2"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s ease-in-out",
                  }}
                  onMouseEnter={e => {
                    e.target.style.transform = "scale(1.05)"
                    e.target.style.boxShadow = "0px 6px 18px rgba(0, 0, 0, 0.2)"
                  }}
                  onMouseLeave={e => {
                    e.target.style.transform = "scale(1)"
                    e.target.style.boxShadow = "0px 4px 12px rgba(0, 0, 0, 0.1)"
                  }}
                >
                  <i className="fa fa-paper-plane mr-2"></i> Create Test
                </Button>
              </Link>
            </Col>

            <Col xs="auto" className="mt-3 mb-5">
              <Link to={"/teacher-class-test"}>
                <Button
                  color="dark"
                  className="px-4 py-2"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s ease-in-out",
                  }}
                  onMouseEnter={e => {
                    e.target.style.transform = "scale(1.05)"
                    e.target.style.boxShadow = "0px 6px 18px rgba(0, 0, 0, 0.2)"
                  }}
                  onMouseLeave={e => {
                    e.target.style.transform = "scale(1)"
                    e.target.style.boxShadow = "0px 4px 12px rgba(0, 0, 0, 0.1)"
                  }}
                >
                  <i className="fa fa-eye mr-2"></i> Class Test Marks
                </Button>
              </Link>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  )
}

export default SubjectHome
