export const GET_CURRENT_ACADID = "GET_CURRENT_ACADID"
export const GET_CURRENT_ACADID_SUCCESS = "GET_CURRENT_ACADID_SUCCESS"
export const GET_CURRENT_ACADID_FAILED = "GET_CURRENT_ACADID_FAILED"

export const GET_CLASS_START = "GET_CLASS_START"
export const GET_CLASS_SUCCESS = "GET_CLASS_SUCCESS"
export const GET_CLASS_FAILED = "GET_CLASS_FAILED"

export const GET_DIVISION_START = "GET_DIVISION_START"
export const GET_DIVISION_SUCCESS = "GET_DIVISION_SUCCESS"
export const GET_DIVISION_FAILED = "GET_DIVISION_FAILED"

export const GET_STUDENT_LIST_START = "GET_STUDENT_LIST_START"
export const GET_STUDENT_LIST_SUCCESS = "GET_STUDENT_LIST_SUCCESS"
export const GET_STUDENT_LIST_FAILED = "GET_STUDENT_LIST_FAILED"

export const GET_SUBJECT_START_COMMON = "GET_SUBJECT_START_COMMON"
export const GET_SUBJECT_SUCCESS_COMMON = "GET_SUBJECT_SUCCESS_COMMON"
export const GET_SUBJECT_FAILED_COMMON = "GET_SUBJECT_FAILED_COMMON"
