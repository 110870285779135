export const GET_DASH_ICONS = "GET_DASH_ICONS"
export const GET_DASH_ICONS_SUCCESS = "GET_DASH_ICONS_SUCCESS"
export const GET_DASH_ICONS_FAIL = "GET_DASH_ICONS_FAIL"

export const GET_BOTTOM_NAV = "GET_BOTTOM_NAV"
export const GET_BOTTOM_NAV_SUCCESS = "GET_BOTTOM_NAV_SUCCESS"
export const GET_BOTTOM_NAV_FAILED = "GET_BOTTOM_NAV_FAILED"

export const GET_URL_SUCCESS = "GET_DASH_SUCCESS"
export const GET_URL_START = "GET_DASH_START"
export const GET_URL_FAIL = "GET_DASH_FAIL"

export const GET_ACAD_ID_START = "GET_ACAD_ID_START"
export const GET_ACAD_ID_SUCCESS = "GET_ACAD_ID_SUCCESS"
export const GET_ACAD_ID_FAIL = "GET_ACAD_ID_FAILL"
