import React, { useEffect, useState } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap"
import { useLocation, useParams } from "react-router-dom"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import moment from "moment/moment"

function Example() {
  const { id} = useParams()
  const location = useLocation()
  const [open, setOpen] = useState("0")
  const token = localStorage.getItem("Token").replace(/"/g, "")

  const [data1, setData1] = useState([])
  const [data2, setData2] = useState([])
  const [mergedData, setMergedData] = useState([])
  const [formData, setFormData] = useState([])

  const toggle = id => {
    setOpen(open === id ? null : id)
  }

  const handleInputChange = (id, field, value) => {
    setFormData(prevFormData =>
      prevFormData.map(item =>
        item.id === id ? { ...item, [field]: value } : item
      )
    )
  }

  const handleSubmit = async id => {
    try {
      const lectureData = mergedData.find(item => item.id === id); // Use mergedData instead of formData
  
      if (!lectureData) {
        toast.error("Lecture data not found!");
        return;
      }
  
      const times = lectureData.lecturetime
        ? lectureData.lecturetime.match(/\d{1,2}:\d{2} [APM]{2}/g)
        : ["", ""];
  
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/logsheet/submit-logsheet",
        data: {
          classdiv_id: lectureData.classdivid_id || "",
          subject_id: lectureData.subject_id || "",
          date: moment(location.state.date).format("DD-MM-YYYY"),
          day_id: lectureData.dayid || "",
          lecture_name: lectureData.name || "",
          arrive_time: times[0] || "",
          depart_time: times[1] || "",
          topic_covered: formData.find(item => item.id === id)?.topics || "",
          observation: formData.find(item => item.id === id)?.teachingAid || "",
          methodology: formData.find(item => item.id === id)?.methodology || "",
        },
        headers: {
          Authorization: token,
        },
      })
        .then(res => {
          toast.success(res.data.message);
        })
        .catch(err => {
          console.error(err);
          toast.error("Something went wrong while submitting");
        });
  
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };
  

  useEffect(() => {
    const fillDayWiseLogsheet = async () => {
      try {
        const res = await axios.post(
          "https://marcopolo.prisms.in/api/logsheet/fill-daywise-logsheet",
          {
            acad_id: location.state.acadid,
            day_id: parseInt(id),
            date: moment(location.state.date).format("DD-MM-YYYY"),
          },
          {
            headers: { Authorization: token },
          }
        )
        setData1(res.data.data.data1)
        setData2(res.data.data.data2)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fillDayWiseLogsheet()
  }, []) 

  useEffect(() => {
    if (data1.length > 0) {
      const merged = data1.map(lecture => {
        const filledDetails = data2.find(
          detail =>
            detail.subject_id == lecture.subject_id &&
            (!detail.lecturename || detail.lecturename === lecture.name)
        )

        return {
          ...lecture,
          topics: filledDetails?.topic_covered || "",
          teachingAid: filledDetails?.observation || "",
          methodology: filledDetails?.methodology || "",
        }
      })

      setMergedData(merged)
    }
  }, [data1, data2])

  useEffect(() => {
    if (mergedData.length > 0) {
      setFormData(mergedData.map(item => ({
        id: item.id,
        topics: item.topics,
        teachingAid: item.teachingAid,
        methodology: item.methodology,
      })))
    }
  }, [mergedData])

  return (
    <div>
      <Accordion open={open} toggle={toggle}>
        {mergedData.map((lecture, index) => (
          <AccordionItem key={lecture.id}>
            <AccordionHeader className="fw-bold" targetId={String(index)}>
              {lecture.lecturetime}
            </AccordionHeader>
            <AccordionBody accordionId={String(index)}>
              <div className="mb-3">
                <span className="mb-2 fw-medium">
                  Subject: {lecture.SUBJECT}
                </span>
                <br />
                <span className="mt-1 fw-medium">
                  Division: {lecture.div_name}
                </span>
              </div>
              <Input
                placeholder="Topics / Sub-Topics"
                value={formData.find(item => item.id === lecture.id)?.topics || ""}
                onChange={e =>
                  handleInputChange(lecture.id, "topics", e.target.value)
                }
              />
              <Input
                className="mt-2 mb-2"
                placeholder="Teaching Aid"
                value={formData.find(item => item.id === lecture.id)?.teachingAid || ""}
                onChange={e =>
                  handleInputChange(lecture.id, "teachingAid", e.target.value)
                }
              />
              <Input
                placeholder="Procedure / Methodology"
                value={formData.find(item => item.id === lecture.id)?.methodology || ""}
                onChange={e =>
                  handleInputChange(lecture.id, "methodology", e.target.value)
                }
              />
              <Button
                color="primary"
                className="mt-3 mb-3"
                onClick={() => handleSubmit(lecture.id)}
              >
                Submit
              </Button>
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
      <ToastContainer />
    </div>
  )
}

const SubmitLog = () => {
  return (
    <div className="page-content">
      <Container className="py-4">
        <Card className="shadow-lg rounded-lg">
          <CardBody>
            <h1
              className="text-center mt-2 mb-4 fs-3"
              style={{ color: "#3c3c3c" }}
            >
              Log Sheet Description
            </h1>
            <Row className="mb-4">
              <Col xl="12" md="12" sm="12">
                <Example />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default SubmitLog
