import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Container } from "reactstrap";
import Spinners from "components/Common/Spinner";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [currentAcadId, setCurrentAcadId] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [iframeSrc, setIframeSrc] = useState("");

  const live_Url = "https://marcopolo.prisms.in";
  const sid = localStorage.getItem("sid")?.replace(/"/g, "");
  const uid = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        setLoading(true);

        const acadResponse = await axios.post(`${live_Url}/api/tscommon/getCurrentAcad`, { sid });
        const acadId = acadResponse.data[0]?.acadid;
        setCurrentAcadId(acadId);

        const schoolResponse = await axios.post("https://topschool.prisms.in/rest/index.php/user_list.json", {
          fun_name: "getSchoolURL",
          sid,
        });

        setSchoolName(schoolResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSchoolData();
  }, [sid]);

  useEffect(() => {
    if (currentAcadId && uid && schoolName) {
      const encodedData = btoa(`${currentAcadId}-${uid}`);
      const newUrl = `https://${schoolName}.prisms.in/cp?db=${encodedData}`;
      setIframeSrc(newUrl);
    }
  }, [currentAcadId, uid, schoolName]);

  return (
    <div className="page-content">
      <Container className="p-0">
        <Card className="p-4">
          {loading ? (
            <Spinners />
          ) : iframeSrc ? (
            <iframe src={iframeSrc} width="100%" height="800px" />
          ) : (
            <p className="text-center">Unable to load content.</p>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default Home;
