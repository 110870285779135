import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const getCurrentAcadIdAPI = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.post(
      `${liveApiUrl}/api/tscommon/getCurrentAcad`,
      payload,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}
//https://marcopolo.prisms.in/api/notification/get-class-div

export const divisionAPI = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.post(
      `https://marcopolo.prisms.in/api/examfillmarks/get-classdiv-list`,
      payload,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}

export const subjectAPI = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.post(
      `https://marcopolo.prisms.in/api/classtestfillmarks/get-classtest-subjects`,
      payload,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}

export const StudentListApi = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.post(
      `https://marcopolo.prisms.in/api/notification/get-student_list`,
      payload,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}
