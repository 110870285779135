import {
  GET_BOTTOM_NAV,
  GET_BOTTOM_NAV_FAILED,
  GET_BOTTOM_NAV_SUCCESS,
  GET_DASH_ICONS,
  GET_DASH_ICONS_FAIL,
  GET_DASH_ICONS_SUCCESS,
  GET_URL_FAIL,
  GET_URL_START,
  GET_URL_SUCCESS,
} from "./constants"

export const getDashIcons = () => ({
  type: GET_DASH_ICONS,
})

export const getDashIconsSuccess = payload => ({
  type: GET_DASH_ICONS_SUCCESS,
  payload,
})

export const getDashIconsFail = payload => ({
  type: GET_DASH_ICONS_FAIL,
  payload,
})

export const getBottomNav = () => ({
  type: GET_BOTTOM_NAV,
})

export const getBottomNavSuccess = payload => ({
  type: GET_BOTTOM_NAV_SUCCESS,
  payload,
})

export const getBottomNavFailed = payload => ({
  type: GET_BOTTOM_NAV_FAILED,
  payload,
})

export const getUrlStart = () => ({
  type: GET_URL_START,
})

export const getUrlSucces = paylaod => ({
  type: GET_URL_SUCCESS,
  payload: paylaod,
})

export const getUrlFail = () => ({
  type: GET_URL_FAIL,
})
