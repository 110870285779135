import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Container } from "reactstrap";
import Spinners from "components/Common/Spinner";

const Home = () => {
  const [iframeSrc, setIframeSrc] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentAcadId, setCurrentAcadId] = useState(null);
  const [schoolName, setSchoolName] = useState(null);

  const token = JSON.parse(localStorage.getItem("Token")) || "";
  const live_Url = "https://marcopolo.prisms.in";
  const sid = localStorage.getItem("sid")?.replace(/"/g, "");
  const uid = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const acadRequest = axios.get(`${live_Url}/api/tscommon/getUserAcadID`, {
          headers: { Authorization: token },
        });

        const schoolRequest = axios.post(
          "https://topschool.prisms.in/rest/index.php/user_list.json",
          { fun_name: "getSchoolURL", sid }
        );

        const [acadResponse, schoolResponse] = await Promise.all([
          acadRequest,
          schoolRequest,
        ]);

        const acadId = acadResponse?.data?.acadId?.acad_id;
        const school = schoolResponse?.data;

        if (acadId) setCurrentAcadId(acadId);
        if (school) setSchoolName(school);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [sid, token]);

  useEffect(() => {
    if (currentAcadId && uid && schoolName) {
      const encoded = btoa(`${currentAcadId}-${uid}`);
      const newUrl = `https://${schoolName}.prisms.in/wf?db=${encoded}`;
      console.log("Generated URL:", newUrl);
      setIframeSrc(newUrl);
    }
  }, [currentAcadId, uid, schoolName]);

  return (
    <div className="page-content">
      <Container className="p-0">
        <Card className="p-0">
          {loading ? <Spinners /> : iframeSrc ? <iframe src={iframeSrc} width="100%" height="800px" /> : <p className="text-center">Unable to load content.</p>}
        </Card>
      </Container>
    </div>
  );
};

export default Home;
