import React from "react"
import ResuableComponent from "../../../common/ResuableHome"
const Home = () => {
  return (
    <div>
      <ResuableComponent
        title={"Assignment"}
        r1={"/assignment"}
        // r2={"/student-submitted-assignmet"}
        t1={"Assignment"}
        // t2={"Submitted Assignment"}
      />
    </div>
  )
}

export default Home
