import React, { useState } from "react"
import RSelect from "react-select"
import { Col, Row, Input } from "reactstrap"
import axios from "axios"
import { toast } from "react-toastify"
const SubmitRemarks = ({
  token,
  t,
  user_id,
  acad_id,
  term_id,
  div_id,
  setSubmit,
}) => {
  const [selectedOptions, setSelectedOptions] = useState({})
  const [remarks, setRemarks] = useState({})

  const handleSelectChange = (sectionLabel, selected) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [sectionLabel]: selected,
    }))
  }

  const handleRemarkChange = (sectionLabel, remark) => {
    setRemarks(prevState => ({
      ...prevState,
      [sectionLabel]: remark,
    }))
  }

  const handleSubmit = async () => {
    try {
      const remarksArray = t.map(section => {
        const remarkArray =
          selectedOptions[section.label]?.map(item => item.value) || []
        const textRemark = remarks[section.label] || ""

        return {
          type: section.type_id,
          remark_array: remarkArray,
          text_remark: textRemark,
        }
      })
      const data = {
        acad_id: acad_id,
        user_id: user_id,
        term_id: term_id,
        classdiv_id: div_id,
        remarks: remarksArray,
      }
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/remark/submit-remark",
        data: data,
        headers: {
          Authorization: token,
        },
      }).then((res)=>{
        console.log(res.data.message)
        toast.success(res.data.message)
        setSubmit(true)
      })
    } catch (error) {
      toast.error("Failed to submit")
    }
  }

  console.table(t);
  console.log(JSON.stringify(t, null, 2));

  return (
    <Row>
      <Row>
        {t &&
          t.map(section => (
            <Col xl="12" key={section.label} className="mt-4">
              <p className="fw-medium fs-6">{section.label}</p>

              <RSelect
                options={section.options}
                isMulti
                closeMenuOnSelect={false}
                onChange={selected =>
                  handleSelectChange(section.label, selected)
                }
                value={selectedOptions[section.label] || []}
                placeholder={`Select ${section.label} Remarks`}
              />

              <Input
                type="textarea"
                className="mt-3"
                placeholder="type your remark here"
                value={remarks[section.label] || ""}
                onChange={e =>
                  handleRemarkChange(section.label, e.target.value)
                }
              />
            </Col>
          ))}

        <Col className="d-flex justify-content-center align-items-center mt-3">
          {/* Submit Button */}
          <button className="btn btn-primary" onClick={handleSubmit}>
            Submit
          </button>
        </Col>
      </Row>
    </Row>
  )
}

export default SubmitRemarks
