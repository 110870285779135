import axios from "axios"
import React, { useEffect, useState, useMemo } from "react"
import TableContainer from "components/Common/TableContainer"
import Spinners from "components/Common/Spinner"
import { toast, ToastContainer } from "react-toastify"
import { useLocation } from "react-router-dom"
import { Container, Col, Row, Card } from "reactstrap"

const CardD = ({ title, value }) => {
  return (
    <Col xs={12} md={6}>
      <div className="d-flex align-items-start">
        <p className="fw-bold text-dark fw-5 mb-0 me-2 w-50">{title}:</p>
        <p className="mb-0 w-50 fw-5 text-dark">{value}</p>
      </div>
    </Col>
  )
}
const MarksOfStud = () => {
  const location = useLocation()
  const status_is_publish = location.state.status_is_publish
  const [marks, setMarks] = useState()
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [studentList, setStudentList] = useState([])
  const [data, setData] = useState([])
  const [submitMarks, setSubmitMarks] = useState()

  const token = JSON.parse(localStorage.getItem("Token"))
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{ color: "#111", whiteSpace: "nowrap" }}>Roll No</div>
        ),
        accessor: "rollNo",
        filterable: true,
      },
      {
        Header: () => (
          <div style={{ color: "#111", whiteSpace: "nowrap" }}>
            Student Name
          </div>
        ),
        accessor: "student",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => (
          <div
            style={{
              color: "#111",
              width: "120px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              whiteSpace: "nowrap",
            }}
          >
            Fill Marks
          </div>
        ),
        accessor: "fillMarks",
        filterable: true,
        Cell: ({ value, row: { index } }) => {
          const handleChange = e => {
            const inputValue = e.target.value

            console.log(inputValue)
            if (inputValue < 0) {
              console.log("Negative value is not allowed!")
              toast.error("Marks cannot be negative")
              return
            }
            if (inputValue === "") {
              setData(prevData => {
                const updatedData = [...prevData]
                updatedData[index] = {
                  ...updatedData[index],
                  fillMarks: "",
                }
                return updatedData
              })
              return
            }

            const newValue = parseFloat(inputValue, 10)

            if (isNaN(newValue)) {
              toast.error("Please enter a valid number")
              return
            }

            if (newValue > marks) {
              console.log(
                "Entered value exceeds max marks: ",
                newValue,
                ">",
                marks
              )
              toast.error(
                `Mark should not be greater than the maximum marks : ${marks}`
              )
              return
            }

            setData(prevData => {
              const updatedData = [...prevData]
              updatedData[index] = {
                ...updatedData[index],
                fillMarks: newValue,
              }
              return updatedData
            })
          }

          // console.log(value)
          // const resetValue = value === 9999 || value === 9998 || value !== null ? "" : value;

          const Mmarks = value === 9999 ? "" : value === 9998 ? "" : value
          return (
            <input
              type={"number"}
              style={{ width: "90px" }}
              readOnly={status_is_publish === 1}
              className="form-control"
              value={Mmarks}
              onChange={handleChange}
            />
          )
        },
      },
    ],
    [marks]
  )

  useEffect(() => {
    const getClassTestMarks = async () => {
      setLoading(true)

      try {
        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/classtestfillmarks/fill-class-test-marks",
          data: {
            classdiv_id: location.state.classdiv_id,
            subject_id: location.state.subject_id,
            acad_id: location.state.acad_id,
            test_id: location.state.test_id,
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setStudentList(res.data.data)
          setLoading(false)
        })
      } catch (error) {
        setLoading(false)
      }
    }

    getClassTestMarks()
  }, [location])

  useEffect(() => {
    if (studentList && studentList.length > 0) {
      const data2 = studentList.map(student => ({
        rollNo: student.rollno,
        student: student.student_name,
        fillMarks: student.marks_obtain,
        max_marks: student.out_of_marks,
        user_id: student.user_id,
        test_id: student.test_id,
      }))

      setData(data2)
      if (data2.length > 0) {
        setMarks(data2[0].max_marks)
      }
    } else {
      setData([])
    }
  }, [studentList])

  useEffect(() => {
    if (data.length > 0) {
      const updatedMarks = data.map(student => ({
        user_ids: student.user_id,
        marks: student.fillMarks,
      }))

      setSubmitMarks(prevSubmitMarks => ({
        ...prevSubmitMarks,
        marks: updatedMarks.map(student => student.marks),
        user_ids: updatedMarks.map(student => student.user_ids),
      }))
    }
  }, [data])

  const handleSubmitMarks = async e => {
    e.preventDefault()
    setLoading(true)
    setSubmitted(false)

    const data = {
      classdiv_id: parseInt(location.state.classdiv_id),
      subject_id: parseInt(location.state.subject_id),
      acad_id: parseInt(location.state.acad_id),
      test_id: parseInt(location.state.test_id),
      ...submitMarks,
    }

    try {
      const response = await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/classtestfillmarks/submit-class-test-marks",
        data: data,
        headers: {
          Authorization: token,
        },
      })

      toast.success(response.data.message)
      setLoading(false)
      setSubmitted(true)
    } catch (error) {
      console.error("Error submitting marks: ", error)
      setLoading(false)
      setSubmitted(false)
      toast.error("Failed to submit marks! Please try again.")
    }
  }

  return (
    <div className="page-content">
      <Container>
        <div className="">
          <Card className="shadow p-4 rounded">
            <Row className="g-3">
              <CardD title={"Test Name"} value={location.state.test_name} />
              <CardD title={"Date"} value={location.state.test_date} />
              <CardD title={"Class Name"} value={location.state.className} />
              <CardD title={"Subject"} value={location.state.subject_name} />
              <CardD title={"Out of Marks"} value={location.state.outOfMarks} />
            </Row>
          </Card>
        </div>
        {loading ? (
          <Spinners />
        ) : (
          <TableContainer
            columns={columns}
            data={data}
            isGlobalFilter={false}
            isAddOptions={false}
            // handleJobClicks={handleJobClicks}
            isJobListGlobalFilter={false}
            isPagination={false}
            iscustomPageSizeOptions={false}
            isShowingPageLength={false}
            customPageSize={100}
            tableClass="table-bordered align-middle nowrap mt-2"
            paginationDiv="col-sm-12 col-md-7"
            pagination="pagination justify-content-end pagination-rounded"
          />
        )}
        {status_is_publish !== 1 && data && data.length > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <button className="btn btn-primary" onClick={handleSubmitMarks}>
              Submit
            </button>
          </div>
        )}
        <ToastContainer />
      </Container>
    </div>
  )
}

export default MarksOfStud
