import React, { useEffect, useState } from "react"
import "./PaidFeesStyle.css"
import axios from "axios"
import { useDispatch } from "react-redux"
import {
  getCurrentAcadid,
  viewPaidFeesDetails,
} from "store/actions"
import { useSelector } from "react-redux"
import moment from "moment"
import Spinners from "components/Common/Spinner"
import { useNavigate } from "react-router-dom"
const PaidFeesDetails = () => {
  const live_Url = "https://marcopolo.prisms.in"

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sid = localStorage.getItem("sid").replace(/"/g, "")
  const uid = localStorage.getItem("user_id")
  const token = JSON.parse(localStorage.getItem("Token"))
  const [currentAcadId, setCurrentAcadId] = useState(null)
  const { paidFees, Loading } = useSelector(state => state.FeesReducer)
  const [studName, setStud] = useState()

   const [schoolName, setSchoolName] = useState()

  const handleClickPrint = reciptno => {
    const message = JSON.stringify({
      action: "Fees",
      rcpno: reciptno,
      studName: studName,
      acad: currentAcadId,
      schoolName: schoolName,
      typeId : paidFees.indicator ? paidFees.indicator[0].value : null,
    })
    window.ReactNativeWebView?.postMessage(message)
  }

  useEffect(() => {
    const getAcadId = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${live_Url}/api/tscommon/getUserAcadID`,
          headers:{
            Authorization: token
          }
        })
        setCurrentAcadId(response.data.acadId.acad_id)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    getAcadId()
  }, [sid])
  useEffect(() => {
    if (currentAcadId) {
      dispatch(
        viewPaidFeesDetails({
          acadid: currentAcadId,
        })
      )
    }
  }, [currentAcadId])

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
  }, [])

  const PaidAmount = data => {
    if (!data || data.length === 0) {
      console.log("No data available.")
      return 0
    }

    const totalAmount = data.reduce(
      (total, current) => total + current.amount,
      0
    )
    // console.log(totalAmount);
    return totalAmount
  }


  useEffect(()=> {
    const getStudentName = async () => {
      const data = {
        sid: sid,
        stud_id: uid,
      }
      await axios({
        method: "POST",
        url: `${live_Url}/api/tscommon/student-name`,
        data,
      }).then(response => {
        // console.log(response.data);
        setStud(response.data[0].student_name)
      }).catch((error)=>{
        console.log(error);
      })
    }
    getStudentName()

    const getSchoolName = async () => {
      const data = {
        fun_name: "getSchoolURL",
        sid: localStorage.getItem("sid").replace(/"/g, ""),
      }
      await axios({
        method: "POST",
        url: "https://topschool.prisms.in/rest/index.php/user_list.json",
        data,
      }).then(response => {
        setSchoolName(response.data)
      })
    }
    getSchoolName();
  },[sid])

  if (Loading) {
    return (
      <div className="page-content">
        <Spinners />
      </div>
    )
  }
  return (
    <div className="page-content m-0 px-0">
      <div className="upper-div">
        <div className="info-section">
          <div className="balance-info">
            <div className="left-section">
              <p>Total Paid</p>
              <h2>
                {/* {paidFees?.paidFees1Arr && calTotalFees(paidFees?.paidFees1Arr)} */}
                {PaidAmount(paidFees.paid_fees)}
              </h2>
            </div>
          </div>
          {/* <div className='actions'>
            <Button>Print Recipt</Button>
            <Button>Transport</Button>

          </div>  */}
        </div>
      </div>

      <div className="bottom-div">
        {paidFees?.paid_fees &&
          paidFees.paid_fees.map(
            (data, index) =>
              data.is_delay !== 1 && (
                <div className="record-card shadow-sm rounded-3" key={index}>
                  <div>
                    <span>Recipt No: {data.rcp_no}</span>
                    <p>
                      <b>{data.category}</b>
                    </p>
                    <span>{moment(data.date).format("DD/MM/YYYY")}</span>
                  </div>

                  <div className="h-100 d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column justify-content-between align-items-end me-2">
                      <p className="m-0">
                        <b>{data.amount}</b>
                      </p>
                      <span>{data.pay_mode}</span>
                    </div>

                    <i
                      className="bx bx-printer fs-4"
                      onClick={() => handleClickPrint(data.rcp_no)}
                    ></i>
                  </div>
                </div>
              )
          )}
      </div>
    </div>
  )
}

export default PaidFeesDetails
