import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const getAttendanceDivAPI = async () => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.get(
      `${liveApiUrl}/api/notification/get-class-div`,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}

export const getStudListAPI = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }
    //api/teacher-attendanceCenter/studlist
    const response = await axios.post(
      `${liveApiUrl}/api/attendance/show-students-attendance`,
      payload,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}

export const markAbsentStudAPI = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }
    ///api/teacher-attendanceCenter/uploadAttendance
    ///submit-students-attendance
    const response = await axios.post(
      `${liveApiUrl}/api/attendance/submit-students-attendance`,
      payload.data,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}

export const getAttendReportApi = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.post(
      `${liveApiUrl}/api/teacher-attendanceCenter/getStudentDailyAttendance`,
      payload,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error Gettting Dashboard Icons:", error)
    throw error
  }
}
