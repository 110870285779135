import {
  GET_BOTTOM_NAV,
  GET_BOTTOM_NAV_FAILED,
  GET_BOTTOM_NAV_SUCCESS,
  GET_DASH_ICONS,
  GET_DASH_ICONS_FAIL,
  GET_DASH_ICONS_SUCCESS,
  GET_URL_START,
  GET_URL_SUCCESS,
  GET_URL_FAIL,
} from "./constants"

const initialState = {
  Loading: false,
  errors: "",
  successMsg: "",
  dashIcons: [],
  bottomNavIcons: [],
  url: "",
}

const DashReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASH_ICONS:
    case GET_BOTTOM_NAV:
      return {
        ...state,
        Loading: true,
        errors: "",
        successMsg: "",
      }
    case GET_DASH_ICONS_SUCCESS:
      return {
        ...state,
        Loading: false,
        dashIcons: action.payload,
      }
    case GET_BOTTOM_NAV_SUCCESS:
      return {
        ...state,
        Loading: false,
        bottomNavIcons: action.payload,
      }
    case GET_DASH_ICONS_FAIL:
    case GET_BOTTOM_NAV_FAILED:
      return {
        ...state,
        Loading: false,
        errros: action.payload,
      }

    case GET_URL_START:
      return {
        ...state,
      }
    case GET_URL_SUCCESS:
      return {
        ...state,
        url: action.payload,
      }

    default:
      return state
  }
}

export default DashReducer
