import {
  GET_CURRENT_ACADID,
  GET_CURRENT_ACADID_FAILED,
  GET_CURRENT_ACADID_SUCCESS,
} from "./Constants"
import * as types from "./Constants"
export const getCurrentAcadid = payload => ({
  type: GET_CURRENT_ACADID,
  payload,
})

export const getCurrentAcadidSuccess = payload => ({
  type: GET_CURRENT_ACADID_SUCCESS,
  payload,
})

export const getCurrentAcadidFailed = payload => ({
  type: GET_CURRENT_ACADID_FAILED,
  payload,
})

// CLASS ACTION
export const getClassStart = payload => ({
  type: types.GET_CLASS_START,
  payload,
})

export const getClassSuccess = payload => ({
  type: types.GET_CLASS_SUCCESS,
  payload,
})

export const getClassFail = payload => ({
  type: types.GET_CLASS_FAILED,
  payload,
})

// DIVISION
export const getDivisionStart = payload => ({
  type: types.GET_DIVISION_START,
  payload,
})

export const getDivisionSuccess = payload => ({
  type: types.GET_DIVISION_SUCCESS,
  payload,
})

export const getDivisionFail = payload => ({
  type: types.GET_DIVISION_FAILED,
  payload,
})

// GET STUDENT LIST :

export const getStudentStart = payload => ({
    type: types.GET_STUDENT_LIST_START,
    payload,
  })
  
  export const getStudentSuccess = payload => ({
    type: types.GET_STUDENT_LIST_SUCCESS,
    payload,
  })
  
  export const getStudentFail = payload => ({
    type: types.GET_STUDENT_LIST_FAILED,
    payload,
  })

  // SUBJECT 

  
export const getSubjectStartCommon = payload => ({
    type: types.GET_SUBJECT_START_COMMON,
    payload,
  })
  
  export const getSubjectSuccessCommon = payload => ({
    type: types.GET_SUBJECT_START_COMMON,
    payload,
  })
  
  export const getSubjectFailCommon = payload => ({
    type: types.GET_SUBJECT_START_COMMON,
    payload,
  })
