import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import { getTestApi, getEventMonthReport } from "./Api"
import * as types from "./Constant"
import { GetTestSuccess, GetTestFailure, GetEventMonthSuccess, GetEventMonthFailure } from "./Action"

function* getTestApiAsync(action) {
  try {
    const response = yield call(getTestApi, action.payload)
    if (response) {
      yield put(GetTestSuccess(response.data.data))
    } else {
      throw response
    }
  } catch (error) {
    console.log("error", error)
    yield put(
      GetTestFailure(
        error.response.data || "Unable to get time table, please try again"
      )
    )
  }
}

function* getEventMonthAsync(action) {
  try {
    const response = yield call(getEventMonthReport, action.payload)
    if (response) {
      yield put(GetEventMonthSuccess(response.data.data))
    } else {
      throw response
    }
  } catch (error) {
    console.log("error", error)
    yield put(
      GetEventMonthFailure(
        error.response.data
      )
    )
  }
}

function* GetTestData() {
  yield takeEvery(types.GET_TEST_MARKS, getTestApiAsync)
  yield takeEvery(types.Event_Month_Report_Start, getEventMonthAsync)

}

export default GetTestData
