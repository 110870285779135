import {
  GET_CURRENT_ACADID,
  GET_CURRENT_ACADID_FAILED,
  GET_CURRENT_ACADID_SUCCESS,
} from "./Constants"
import * as types from "./Constants"

const initialState = {
  Loading: false,
  successMsg: "",
  errors: "",
  acadID: [],
  schClass: [],
  division: [],
  studentList: [],
  subject: [],
}

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_ACADID:
      return {
        ...state,
        Loading: true,
      }
    case GET_CURRENT_ACADID_SUCCESS:
      return {
        ...state,
        Loading: false,
        acadID: action.payload,
      }
    case GET_CURRENT_ACADID_FAILED:
      return {
        ...state,
        Loading: false,
        errors: action.payload,
      }

    // class
    case types.GET_CLASS_START:
      return {
        ...state,
      }
    case types.GET_CLASS_SUCCESS:
      return {
        ...state,
        schClass: action.payload,
        Loading: false,
      }
    case types.GET_CLASS_FAILED:
      return {
        ...state,
        schClass: [],
        Loading: false,
      }

    // division
    case types.GET_DIVISION_START:
      return {
        ...state,
      }
    case types.GET_DIVISION_SUCCESS:
      return {
        ...state,
        division: action.payload,
        Loading: false,
      }
    case types.GET_DIVISION_FAILED:
      return {
        ...state,
        division: [],
        Loading: false,
      }

    // student
    case types.GET_STUDENT_LIST_START:
      return {
        ...state,
      }
    case types.GET_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        studentList: action.payload,
        Loading: false,
      }
    case types.GET_STUDENT_LIST_FAILED:
      return {
        ...state,
        studentList: [],
        Loading: false,
      }

    // subject :

    case types.GET_SUBJECT_START_COMMON:
      return {
        ...state,
      }
    case types.GET_SUBJECT_SUCCESS_COMMON:
      return {
        ...state,
        subjectList: action.payload,
        Loading: false,
      }
    case types.GET_SUBJECT_FAILED_COMMON:
      return {
        ...state,
        subjectList: [],
        Loading: false,
      }
    default:
      return state
  }
}
export default CommonReducer
