import React, { useEffect, useState, useMemo } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentAcadid, getDivisionStart } from "store/Common/Actions"
const Home = () => {
  const dispatch = useDispatch()
  const { acadID, division } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))
  let baseUrl = "https://marcopolo.prisms.in/api/examfillmarks"

  const [loading, setLoading] = useState(false)
  const [div_id, setDiv_id] = useState("")
  const [EventWiseInfo, setSetEventWiseInfo] = useState()
  const [divName, setDivName] = useState()
  const [studentList, setStudentList] = useState([])
  const [date, setDate] = useState()
  const [remarks, setRemarks] = useState({})
  const [academic, setAcademic] = useState([])
  const [acad_id, setAcad_id] = useState("")

  const handleRemarkChange = (userId, value) => {
    setRemarks(prev => ({
      ...prev,
      [userId]: value,
    }))
  }

  const getAcademicYear = async () => {
    try {
      await axios({
        method: "GET",
        url: `${baseUrl}/get-Academic-year`,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setAcademic(res.data.data)
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }

    getAcademicYear()
  }, [])

  useEffect(() => {
    if (acad_id || acadID.acadid) {
      dispatch(
        getDivisionStart({
          acad_id: acad_id || acadID.acadid,
        })
      )
    }
  }, [acadID.acadid, acad_id])

  useEffect(() => {
    const getStudentList = async () => {
      setLoading(true)
      try {
        const response = await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/notification/get-student_list",
          data: {
            acad_id: acad_id || acadID.acadid,
            classdiv_id: div_id,
          },
          headers: {
            Authorization: token,
          },
        })
        setStudentList(response.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    const getEventWiseRecord = async () => {
      try {
        await axios({
          method: "POST",
          url: `https://marcopolo.prisms.in/api/teacher-anecdotal/get-datewise-fill-records`,
          data: {
            acad_id: acad_id || acadID.acadid,
            division_id: div_id,
            date: date,
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setSetEventWiseInfo(res.data.data)
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (div_id && (acad_id || acadID.acadid)) {
      getStudentList()
      getEventWiseRecord()
    }
  }, [acadID.acadid, div_id, acad_id])

  useEffect(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    setDate(formattedDate)
  }, [])
  const handleSubmit = async studid => {
    try {

      const remark = remarks[studid] ?? "";
      const data = {
        acad_id: acad_id || acadID.acadid,
        stud_id: studid,
        division_id: div_id,
        date: date,
        remark: remark,
      }
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/teacher-anecdotal/eventwise",
        data: data,
        headers: {
          Authorization: token,
        },
      })
        .then(res => {
          toast.success(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {}
  }

  console.log("Event ")
  console.log(EventWiseInfo)
  return (
    <div className="">
      <Container>
        <Card className="shadow-lg rounded-lg p-0">
          <CardBody className="p-2">
            <Row>
              <Col xl="12" md="12" sm="12">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={e => setAcad_id(e.target.value)}
                >
                  <option value={acadID.acadid}>{acadID.academics}</option>
                  {academic &&
                    academic
                      .filter(year => year.acadid !== acadID.acadid)
                      .map(year => (
                        <option key={year.acadid} value={year.acadid}>
                          {year.academics}
                        </option>
                      ))}
                </select>
              </Col>
              <Col xl="4" className="mt-3">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={e => {
                    const selectedItem = JSON.parse(e.target.value)
                    setDiv_id(selectedItem.id)
                    setDivName(selectedItem.div_name)
                  }}
                >
                  <option>Select Division</option>
                  {division.length > 0 &&
                    division.map((item, index) => (
                      <option
                        key={item.id}
                        value={JSON.stringify({
                          id: item.id,
                          div_name: item.div_name,
                        })}
                      >
                        {item.div_name}
                      </option>
                    ))}
                </select>
              </Col>

              <Col xl="12" md="12" sm="12" className="mt-3">
                <Input
                  type="date"
                  value={date}
                  onChange={e => setDate(e.target.value)}
                />
              </Col>
            </Row>

            {loading ? (
              <Spinners />
            ) : (
              <Table className="mt-3" bordered striped responsive>
                <thead className="mt-3 table-info">
                  <tr>
                    <th>Student Name</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {studentList &&
                    studentList.map((item, idx) => {

                      const studentRemark =
                      remarks[item.user_id] ??
                      (Array.isArray(EventWiseInfo)
                        ? EventWiseInfo.find(report => report.user_id === item.user_id)?.remark
                        : "") ??
                      "";

                      const hasRemark = studentRemark.trim() !== ""

                      return (
                        <tr key={idx}>
                          <td>{item.student_name}</td>
                          <td>
                            <Input
                              type="textarea"
                              className="form-control mb-3"
                              placeholder="Enter Remarks Here..."
                              value={studentRemark}
                              onChange={e =>
                                handleRemarkChange(item.user_id, e.target.value)
                              }
                            />
                            <Button
                              className="btn btn-success"
                              onClick={() =>
                                handleSubmit(item.user_id, studentRemark)
                              }
                            >
                              Submit
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Container>
      <ToastContainer />
    </div>
  )
}

export default Home
