export const GET_TEST_MARKS = "GET_TEST_MARKS"
export const GET_TEST_MARKS_SUCCESS = "GET_TEST_MARKS_SUCCESS"
export const GET_TEST_MARKS_FAILED = "GET_TEST_MARKS_FAILED"
export const GET_TEST_MARKS_RESET = "GET_TEST_MARKS_RESET"


//Event and Month/subject wise report

export const Event_Month_Report_Start = "Event_Month_Report_Start"
export const Event_Month_Report_SUCCESS = "Event_Month_Report_SUCCESS"
export const Event_Month_Report_FAILED = "Event_Month_Report_FAILED"
export const Event_Month_Report_RESET = "Event_Month_Report_RESET"