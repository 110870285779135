import React, { useState, useEffect } from "react"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
import axios from "axios"
import {
  createNotification,
  getClassDiv,
  getCurrentAcadid,
} from "store/actions"
import FileUpload from "pages/Notifications/Create/FileUpload"
import Spinners from "components/Common/Spinner"
import { toast, ToastContainer } from "react-toastify"

const ClassTabs = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { Loading, errors, classDivList, successMsg } = useSelector(
    state => state.NotificationReducer
  )
  const { acadID } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))

  const [activeTab, setActiveTab] = useState(null)
  const [addedTabs, setAddedTabs] = useState([])
  const [studentList, setStudentList] = useState({})
  const [selectedStudents, setSelectedStudents] = useState([])
  const [checkBox, setCheckBox] = useState({
    webCheck: 1,
    appCheck: 1,
    emailCheck: 0,
    smsCheck: 0,
  })
  const [ip, setIp] = useState("")
  const [uploadFiles, setUploadFiles] = useState()

  const fetchIp = async () => {
    try {
      const { data } = await axios.get("https://api.ipify.org?format=json")
      console.log(data.ip)
      setIp(data.ip)
    } catch (error) {
      console.error("Error fetching the IP address:", error)
    }
  }
  useEffect(() => {
    dispatch(getClassDiv())
    fetchIp()
  }, [dispatch])

  const handleCheckBox = e => {
    const { name, checked } = e.target
    setCheckBox(prev => ({ ...prev, [name]: checked ? 1 : 0 }))
  }

  const handleClassDivSelection = e => {
    const { classDiv, id } = JSON.parse(e.target.value)
    if (!addedTabs.some(tab => tab.classDiv === classDiv)) {
      setAddedTabs(prev => [...prev, { classDiv, id }])
    }
    setActiveTab(classDiv)
    fetchStudentList(id)
  }

  const fetchStudentList = async classdiv_id => {
    try {
      const { data } = await axios.post(
        "https://marcopolo.prisms.in/api/notification/get-student_list",
        { acad_id: acadID.acadid, classdiv_id },
        { headers: { Authorization: token } }
      )
      setStudentList(prev => ({ ...prev, [classdiv_id]: data.data }))
    } catch (error) {
      console.error("Error fetching student list:", error)
    }
  }

  const handleStudentSelection = (e, studentId) => {
    const { checked } = e.target
    setSelectedStudents(prev =>
      checked ? [...prev, studentId] : prev.filter(id => id !== studentId)
    )
  }

  const handleAnnouncementTypeChange = e => {
    if (e.target.value === "6") {
      // navigate("/create-notifications", (state = { ann_type: "6" }))
      navigate("/create-notifications", { state: { ann_type: "6" } })
    }
  }

  const [textError, setTextError] = useState()

  const [data, setData] = useState({
    classdiv_id: [],
    anntext: "",
    txt_typ: "English",
    ann_type: "3",
    acad_id: acadID.acadid,
    sender_IP_adress: ip,
  })

  const renderCheckbox = (name, label, defaultChecked = false) => (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        name={name}
        type="checkbox"
        id={`${name}Select`}
        onChange={handleCheckBox}
        defaultChecked={defaultChecked}
      />
      <label className="form-check-label" htmlFor={`${name}Select`}>
        {label}
      </label>
    </div>
  )

  useEffect(() => {
    if (selectedStudents.length > 0 || acadID.acadid || ip) {
      setData(prev => ({
        ...prev,
        classdiv_id: selectedStudents,
        acad_id: acadID.acadid,
        sender_IP_adress: ip,
      }))
    }
  }, [selectedStudents])

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    } else {
      toast.error("SID missing")
    }
  }, [])

  const handleSubmit = async () => {
    // console.log(data)
    try {
      if (!data.anntext || data.anntext.trim() === "") {
        setTextError("Please enter text")
        return
      }
      dispatch(
        createNotification({
          ...data,
          ...uploadFiles,
          an_email: checkBox.emailCheck,
          an_app: checkBox.appCheck,
          an_web: checkBox.webCheck,
          an_sms: checkBox.smsCheck,
        })
      )
    } catch (error) {
      console.error("Error submitting notification:", error)
    }
  }

  useEffect(() => {
    if (errors && errors !== null) {
      toast.error(errors)
    }

    if (successMsg && successMsg !== null) {
      toast.success(successMsg)

      setData(prev => ({
        ...prev,
        classdiv_id: [],
        anntext: "",
        txt_typ: "English",
        ann_type: "3",
        acad_id: acadID.acadid,
        sender_IP_adress: ip,
      }))
      setStudentList([])
      setActiveTab(null)
      setAddedTabs([])
      setSelectedStudents([])
    }
  }, [errors, successMsg])

  return (
    <div className="page-content">
      <Container>
        <Card>
          {Loading ? (
            <Spinners />
          ) : (
            <CardBody>
              <Row className="d-flex flex-column">
                <Col>
                  <FormGroup className="d-flex justify-content-around px-0 mx-0">
                    {renderCheckbox("webCheck", "Web", true)}
                    {renderCheckbox("appCheck", "App", true)}
                    {renderCheckbox("emailCheck", "Email", true)}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="SelectAnnType" className="form-label">
                      Announcement Type
                    </Label>
                    <Input
                      id="SelectAnnType"
                      name="ann_type"
                      value={data.ann_type}
                      type="select"
                      onChange={handleAnnouncementTypeChange}
                    >
                      <option value="">Select Announcement Type</option>
                      <option value="6">Homework/Activity</option>
                      <option value="3">Student Specific</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col>
                  <select
                    className="form-control"
                    onChange={handleClassDivSelection}
                  >
                    <option value="">Select Divisions</option>
                    {classDivList?.map((classDiv, index) => (
                      <option
                        value={JSON.stringify({
                          classDiv: classDiv.div_name,
                          id: classDiv.id,
                        })}
                        key={index}
                      >
                        {classDiv.div_name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>

              <Nav tabs className="mt-3">
                {addedTabs.map(({ classDiv }, index) => (
                  <NavItem key={index}>
                    <NavLink
                      className={classnames({
                        active: activeTab === classDiv,
                        "bg-primary text-white": activeTab === classDiv,
                      })}
                      onClick={() => setActiveTab(classDiv)}
                    >
                      {classDiv}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <Col className="mt-2">
              <span className="fw-medium text-dark">{selectedStudents && selectedStudents.length > 0 && `Total Student ${selectedStudents.length}`}</span>
              </Col>
              <TabContent className="mt-4" activeTab={activeTab}>
                {addedTabs.map(({ classDiv, id }, index) => (
                  <TabPane key={index} tabId={classDiv}>
                    { studentList && studentList[id]?.length ? (
                      <ul style={{ listStyle: "none" }}>
                        {studentList[id].map(student => (
                          <li key={student.user_id}>
                            <input
                              type="checkbox"
                              id={`student-${student.user_id}`}
                              value={student.user_id}
                              onChange={e =>
                                handleStudentSelection(e, student.user_id)
                              }
                            />
                            <label htmlFor={`student-${student.user_id}`}>
                              {student.student_name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No students found</p>
                    )}
                  </TabPane>
                ))}
              </TabContent>
              <Col>
                <FormGroup>
                  <Label className="form-label">Language</Label>
                  <Input
                    type="select"
                    onChange={e =>
                      setData(prev => ({
                        ...prev,
                        txt_typ: e.target.value,
                      }))
                    }
                  >
                    <option value="English">English</option>
                    <option value="Other">Other Language</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label className="form-label">Enter Notification Text</Label>
                  <Input
                    type="textarea"
                    value={data.anntext}
                    onChange={e => {
                      setData(prev => ({
                        ...prev,
                        anntext: e.target.value,
                      }))
                      setTextError("")
                    }}
                    invalid={!!textError}
                  />
                  {textError && (
                    <FormFeedback type="invalid">{textError}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <div>
                  <h6 className="text-muted fw-4 py-1">
                    Upload up to 3 attachments.
                  </h6>
                </div>
                <FileUpload setUploadFiles={setUploadFiles} />
              </Col>

              <Col className="d-flex align-items-center justify-content-center">
                <Button color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Col>
            </CardBody>
          )}
        </Card>
      </Container>
    </div>
  )
}

export default ClassTabs
